<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto justify-content-center">
        <Spinner v-if="!mounted" />
        <div v-else>
          <small class="text-danger" v-if="getErrors.profile">
            {{ getErrors.profile }}
          </small>

          <div class="alert alert-danger" v-if="getErrors.get_tenant">
            {{ getErrors.get_tenant }}
          </div>
          <div class="alert alert-danger" v-if="getErrors.tenant">
            {{ getErrors.tenant }}
          </div>

          <div class="w-100" v-if="step == 1">
            <h1>
              {{ $t("Do you have a Dutch identity and Digid?") }}
            </h1>

            <p class="fw-medium text-gray">
              {{
                $t(
                  "We want to know if you have a DigiD account in order to determine the next steps."
                )
              }}
            </p>

            <label for="">
              {{ $t("Do you have a Dutch identity and") }}
              <span class="text-info">{{ $t("DigiD account") }}</span
              >?
            </label>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                class="mb-2"
                v-model="form.expat"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="0"
              >
                {{ $t("yes") }}
              </b-form-radio>
              <b-form-radio
                class="mb-2"
                v-model="form.expat"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="1"
              >
                {{ $t("no") }}
              </b-form-radio>
            </b-form-group>

            <div class="w-100 mt-4">
              <button
                class="btn btn-primary btn-block mb-3"
                :disabled="getLoadings.tenant || form.expat == null"
                @click="submit"
              >
                {{ $t("Next step") }}
                <b-spinner
                  v-if="getLoadings.tenant"
                  small
                  label="Spinning"
                  variant="white"
                >
                </b-spinner>
              </button>
              <button class="btn btn-outline-white btn-block" @click="previous">
                {{ $t("Previous step") }}
              </button>
            </div>
          </div>

          <div class="w-100" v-if="step == 2">
            <h1>{{ $t("How long have you been an entrepeneur?") }}</h1>
            <p class="fw-medium text-gray">
              {{ $t("entrepeneur_howlong_description") }}
            </p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                class="mb-2"
                v-model="form.professional_years"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="more_than_2"
              >
                {{ $t("More than 2 years") }}
              </b-form-radio>
              <b-form-radio
                class="mb-2"
                v-model="form.professional_years"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                value="less_than_2"
              >
                {{ $t("Less than 2 years") }}
              </b-form-radio>
            </b-form-group>

            <small class="text-danger" v-if="getErrors.profile">
              {{ getErrors.profile }}
            </small>
            <div class="w-100 mt-4">
              <button
                class="btn btn-primary btn-block mb-3"
                :disabled="getLoadings.profile || !form.professional_years"
                @click="submit"
              >
                {{ $t("Next step") }}
                <b-spinner
                  v-if="getLoadings.profile"
                  small
                  label="Spinning"
                  variant="white"
                >
                </b-spinner>
              </button>
              <button class="btn btn-outline-white btn-block" @click="previous">
                {{ $t("Previous step") }}
              </button>
            </div>
          </div>

          <div class="w-100" v-if="step == 3">
            <h1>
              {{ $t("Please note: income does not count in full") }}
            </h1>
            <p class="fw-medium text-gray">
              {{ $t("entrepeneur_howlong_description") }}
            </p>

            <small class="text-danger" v-if="getErrors.profile">
              {{ getErrors.profile }}
            </small>
            <div class="w-100 mt-4">
              <button class="btn btn-primary btn-block mb-3" @click="submit">
                {{ $t("Next step") }}
                <b-spinner
                  v-if="getLoadings.profile"
                  small
                  label="Spinning"
                  variant="white"
                >
                </b-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <!-- <img src="../../../assets/images/nordigen/nordigen_logo.png" alt=""> -->
          <img src="../../../assets/images/id-card.svg" alt="" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import Spinner from "../../../components/ui/Spinner.vue";

export default {
  data() {
    return {
      error: "",
      form: {
        expat: null,
      },
      mounted: false,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    Spinner,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    step() {
      return this.$route.params.step || 1;
    },
  },
  async mounted() {
    await this.load();
    this.mounted = true;
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    ...mapMutations("tenant", ["setTenant"]),
    previous() {
      this.$router.go(-1);
    },
    async load() {
      this.setTenant(null);
      let tenant_id = this.$route.params.tenant_id;
      if (tenant_id) {
        await this.getTenant(tenant_id);
        this.form.tenant_id = this.tenant.id;
      }
      // this.step = this.$route.params.step;
      let expat = this.tenant?.expat;
      this.form.expat = expat;
    },
    async submit() {
      await this.updateTenant(this.form);
      this.$router.push(`/user/income/1/${this.tenant.id}`);
    },
  },
  watch: {
    $route() {
      console.log("route watch");
      // this.step = this.$route.params.step;
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
